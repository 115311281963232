import { Avatar } from "@mui/material";
import React from "react";
import { paperclip, smileIcon, perfect, photoIcon } from "../../../assets";
import moment from "moment";
import { isImage } from "../../../utilities/isImage";
import bytesToSize from "../../../utilities/bytesToSize";
import { saveAs } from "file-saver";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "moment/locale/fr";
import "moment/locale/ar";
const Comments = ({ activeToDoList, id }) => {
  const saveFile = (url) => {
    saveAs(url);
  };
  return (
    <>
      {id === 0 ? (
        <p className="comment-text">{activeToDoList?.description}</p>
      ) : (
        activeToDoList?.comments?.map((comment) => (
          <div key={comment?._id} className="comment-container">
            <div className="user-duration-wrapper">
              <div className="img-username-wrapper">
                <Avatar
                  src={comment?.client?.avatar}
                  alt="commentuser"
                  className="avatar-comment"
                />
                <div className="username-role-wrapper">
                  <span id="usename" className="username-comment">
                    {comment?.client?.firstName}&nbsp;
                    {comment?.client?.lastName}
                  </span>
                  <span id="role" className="department-comment">
                    {comment?.client?.department
                      ? `@${comment?.client?.department?.name}`
                      : null}
                  </span>
                </div>
              </div>
              <span className="comment-time">
                {moment(comment?.createdAt).fromNow()}
              </span>
            </div>
            <p className="comment-text">{comment?.content}</p>
            {comment?.file && (
              <div className="file-number-wrapper">
                <div className="file-holder">
                  <LazyLoadImage
                    src={isImage(comment?.file?.url) ? photoIcon : paperclip}
                    alt={
                      isImage(comment?.file?.url) ? "photo-icon" : "paper clip"
                    }
                  />
                </div>
                 
                {comment?.file?.url && (
                  <span
                    className="file-name-comment"
                    onClick={() => saveFile(comment?.file?.url)}
                  >
                    {comment?.file?.name}&nbsp;&nbsp;
                    <span id="pdf-size">
                      ({bytesToSize(comment?.file?.size)})
                    </span>
                  </span>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </>
  );
};

export default Comments;
