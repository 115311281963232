import React from "react";
import { Popover } from "../../../components";
import { openusers, calendar, edit } from "./../../../assets";
import { dashToEmptySpace } from "../../../utilities/dashToEmptySpace";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const IssueItem = ({
  activeToDoList,
  anchorEl,
  anchorElTags,
  anchorElDueDate,
  setAnchorEl,
  setAnchorElTags,
  setAnchorElDueDate,
  divRef,
}) => {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickTags = (event) => {
    setAnchorElTags(event.currentTarget);
  };
  const handleClickDueDate = (event) => {
    setAnchorElDueDate(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseTags = () => {
    setAnchorElTags(null);
  };
  const handleCloseDueDate = () => {
    setAnchorElDueDate(null);
  };
  const openpopover = Boolean(anchorEl);
  const openpopover2 = Boolean(anchorElDueDate);
  const openpopover3 = Boolean(anchorElTags);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const { operator, date, ShowIssueItemTags } = useSelector(
    (state) => state.todolist
  );
  const { t } = useTranslation();
  return (
    <div className="issues-wrapper">
      <div className="img-text-answer-wrapper">
        <div className="img-text-wrapper">
          <LazyLoadImage src={openusers} alt={"assigned-to-:"} />
          <span>{t("Assigned To").replace(" ", "\xa0")}:</span>
        </div>
        <div
          id="empty"
          onClick={handleClick}
          className={!activeToDoList ? "disabled-div" : ""}
        >
          {operator
            ? dashToEmptySpace(`${operator?.firstName}-${operator?.lastName}`)
            : activeToDoList?.assigned_to
            ? dashToEmptySpace(
                `${activeToDoList?.assigned_to?.firstName}-${activeToDoList?.assigned_to?.lastName}`
              )
            : "-----------"}
        </div>
        <Popover
          id={"assignedto"}
          open={openpopover}
          anchorEl={anchorEl}
          divRef={divRef}
          setAnchorEl={setAnchorEl}
          onClose={handleClose}
        />
      </div>
      <div className="img-text-answer-wrapper">
        <div className="img-text-wrapper">
          <LazyLoadImage src={calendar} alt={"calendar"} id="calendar" />
          <span>{t("Due Date").replace(" ", "\xa0")}:</span>
        </div>
        <div
          id="empty"
          onClick={handleClickDueDate}
          className={!activeToDoList ? "disabled-div" : ""}
        >
          {date
            ? new Date(date).toLocaleDateString(
                localStorage.getItem("i18nextLng"),
                options
              )
            : new Date(activeToDoList?.due_date).toLocaleDateString(
                localStorage.getItem("i18nextLng"),
                options
              )
            ? new Date(activeToDoList?.due_date).toLocaleDateString(
                localStorage.getItem("i18nextLng"),
                options
              )
            : t("Choose a date")}
        </div>{" "}
        <Popover
          id={"duedate"}
          open={openpopover2}
          anchorEl={anchorElDueDate}
          setAnchorEl={setAnchorElDueDate}
          divRef={divRef}
          onClose={handleCloseDueDate}
        />
      </div>
      <div className="img-text-answer-wrapper">
        <div className="img-text-wrapper">
          <LazyLoadImage
            src={edit}
            alt={"edit"}
            style={{ width: "21px", height: "20px" }}
          />
          <span>{t("Tags").replace(" ", "\xa0")}:</span>
        </div>
        <div
          id="empty"
          onClick={handleClickTags}
          className={!activeToDoList ? "disabled-div" : ""}
        >
          {(ShowIssueItemTags?.length !== 0
            ? ShowIssueItemTags
            : activeToDoList?.tags
          )?.map((item) => {
            return "\xa0" + item?.name;
          })}
          {ShowIssueItemTags?.length === 0 &&
            activeToDoList?.tags?.length === 0 &&
            t("Choose a Tag")}
        </div>{" "}
        <Popover
          id={"tags"}
          open={openpopover3}
          anchorEl={anchorElTags}
          setAnchorEl={setAnchorElTags}
          divRef={divRef}
          onClose={handleCloseTags}
        />
      </div>
    </div>
  );
};

export default IssueItem;
