import React from "react";
import { styled } from "@mui/material/styles";
import data from "./../../utilities/constants";
import { SubSideBar, ContentHeader } from "../../components";
import { dashToEmptySpace } from "./../../utilities/dashToEmptySpace";
const TodoList = ({ children }) => {
  const RootStyle = styled("div")(() => ({
    display: "flex",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  }));
  const MainStyle = styled("div")(() => ({
    flexGrow: 1,
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: data.APP_BAR_MOBILE + 3,
  }));

  return (
    <RootStyle>
      <SubSideBar />
      <div style={{ width: "100%" }}>
        <ContentHeader headerTitle={dashToEmptySpace("Open-Issues")} />
        <MainStyle> {children}</MainStyle>
      </div>{" "}
    </RootStyle>
  );
};

export default TodoList;
